import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Error from '../Error'
import Loading from '../Loading'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const query = gql`
  query getConfiguration {
    getConfiguration {
      logins {
        title
        description
        buttonText
        loginUrl
        ePPN
      }
    }
  }
`

const spoofUserMutation = gql`
  mutation spoofUser($username: String!, $institution: String) {
    spoofUser(username: $username, institution: $institution) {
      username
    }
  }
`

export default function Spoof() {
  const classes = useStyles()
  const [username, setUsername] = React.useState('')
  const [institution, setInstitution] = React.useState('')
  const { loading, error, data } = useQuery(query)
  const [spoofUser, { error: error2 }] = useMutation(spoofUserMutation)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()

      await spoofUser({ variables: { username, institution } })

      window.location.reload()
    } catch (e) {
      console.log(e)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Paper elevation={0} variant="outlined">
      <Typography variant="h5" style={{ padding: '10px' }}>
        Admin
      </Typography>
      <Divider />
      <br />
      <div style={{ padding: '10px' }}>
        <form onSubmit={handleSubmit}>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel>Institution</InputLabel>
            <Select
              value={institution}
              onChange={(event) => setInstitution(event.target.value)}
              displayEmpty
            >
              {data?.getConfiguration?.logins?.map((login) => (
                <MenuItem key={`institution_${login.ePPN}`} value={login.ePPN}>
                  {login.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl className={classes.formControl} variant="outlined">
            <TextField
              label="Username (eName or NETID)"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
            />
          </FormControl>

          <br />
          {error2 && (
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <Error error={error2} />
            </div>
          )}
          <br />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={username === ''}
          >
            Login as user
          </Button>
        </form>
      </div>
    </Paper>
  )
}
